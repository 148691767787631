import React from 'react';
import {
    _i,
    _q,
    _findAncestor,
    _inView,
    _qa,
} from '../../../functions/alisma_library_react';
import { _hasClass } from '../controls/lib11';
export default class PCScroll extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = {
            loaded: false,
            quietPeriod: 600,
            animationTime: 400,
            lastAnimation: 0,
            tileCount: 1,
            gap: 30,
        };
        this.state = this.initialState;
        this.init_scroll = this.init_scroll.bind(this);
        this.keydownHandler = this.keydownHandler.bind(this);
        this.mouseWheelHandler = this.mouseWheelHandler.bind(this);
        this.scrollTo = this.scrollTo.bind(this);
        this.swipeEvents = this.swipeEvents.bind(this);
        this.moveDown = this.moveDown.bind(this);
        this.moveUp = this.moveUp.bind(this);
        this.moveTo = this.moveTo.bind(this);
    }
    componentDidMount() {
        setTimeout(() => {
            this.setState({
                loaded: this.props.loaded,
                tileCount: this.props.tileCount,
            });
            document.addEventListener('swipeDown', this.moveUp);
            document.addEventListener('swipeUp', this.moveDown);
            _i('content').addEventListener('keydown', this.keydownHandler);
            _i('content').addEventListener(
                'mousewheel',
                this.mouseWheelHandler
            );
            _i('content').addEventListener(
                'DOMMouseScroll',
                this.mouseWheelHandler
            );
            this.swipeEvents();
        }, 0);
    }
    componentWillUnmount() {
        this.setState(this.initialState);
        document.removeEventListener('swipeDown', this.moveUp);
        document.removeEventListener('swipeUp', this.moveDown);
        _i('content').removeEventListener('keydown', this.keydownHandler);
        _i('content').removeEventListener('mousewheel', this.mouseWheelHandler);
        _i('content').removeEventListener(
            'DOMMouseScroll',
            this.mouseWheelHandler
        );
    }
    mouseWheelHandler(e) {
        var delta = e.wheelDelta || -e.detail;
        this.init_scroll(e, delta);
    }
    keydownHandler(e) {
        var tag = e.target.tagName.toLowerCase();
        switch (e.which) {
            case 38:
                if (tag !== 'input' && tag !== 'textarea') this.moveUp();
                break;
            case 40:
                if (tag !== 'input' && tag !== 'textarea') this.moveDown();
                break;
            default:
                return;
        }
        return false;
    }
    swipeEvents(e) {
        var startX, startY;
        const content = _i('content');
        content.addEventListener('touchstart', touchstart, false);
        content.addEventListener('swiped', touchstart, false);
        function touchstart(e) {
            var touches = e.touches;
            if (touches && touches.length) {
                startX = touches[0].pageX;
                startY = touches[0].pageY;
                content.addEventListener('touchmove', touchmove);
                if (
                    (e.target.nodeName !== 'BUTTON' ||
                        e.target.tagName !== 'BUTTON') &&
                    (e.target.nodeName !== 'INPUT' ||
                        e.target.tagName !== 'INPUT') &&
                    (e.target.nodeName !== 'TEXTAREA' ||
                        e.target.tagName !== 'TEXTAREA') &&
                    e.target.className !== 'alismaSplatLogo' &&
                    !_findAncestor(e.target, '.alismaSplatLogo') &&
                    !_findAncestor(e.target, '#back-to-top') &&
                    !_findAncestor(e.target, '#to-top') &&
                    !_findAncestor(e.target, 'A')
                ) {
                    e.preventDefault();
                    e.stopPropagation();
                }
            }
        }
        function touchmove(e) {
            var touches = e.touches;
            if (touches && touches.length) {
                e.preventDefault();
                var deltaX = startX - touches[0].pageX;
                var deltaY = startY - touches[0].pageY;
                var event;
                if (deltaX >= 30) {
                    event = new Event('swipeLeft');
                    document.dispatchEvent(event);
                }
                if (deltaX <= -30) {
                    event = new Event('swipeRight');
                    document.dispatchEvent(event);
                }
                if (deltaY >= 30) {
                    event = new Event('swipeUp');
                    document.dispatchEvent(event);
                }
                if (deltaY <= -30) {
                    event = new Event('swipeDown');
                    document.dispatchEvent(event);
                }
                if (Math.abs(deltaX) >= 30 || Math.abs(deltaY) >= 30) {
                    //
                    content.removeEventListener('touchmove', touchmove);
                }
            }
        }
    }
    scrollTo(element, to, duration) {
        if (duration < 0) return;
        var difference = to - element.scrollTop;
        var perTick = (difference / duration) * 10;
        setTimeout(function () {
            element.scrollTop = element.scrollTop + perTick;
            if (element.scrollTop === to) return;
            this.scrollTo(element, to, duration - 10);
        }, 10);
    }
    init_scroll(e, delta) {
        var deltaOfInterest = delta,
            timeNow = new Date().getTime();
        if (
            timeNow - this.state.lastAnimation <
            this.state.quietPeriod + this.state.animationTime
        ) {
            return false;
        }
        if (deltaOfInterest < 0) {
            this.moveDown();
        } else {
            this.moveUp();
        }
        this.setState({ lastAnimation: timeNow });
    }
    moveUp(e) {
        e && e.preventDefault();
        if (this.props.tileNum > 1) {
            let newPos = (this.props.tileNum - 2) * 100 * -1;
            this.props.onChange(newPos, Number(this.props.tileNum) - 1);
        } else {
            return false;
        }
    }
    moveDown(e) {
        e && e.preventDefault();
        if (this.props.tileNum < _qa('.tile--section').length) {
            let newPos = this.props.tileNum * 100 * -1;
            this.props.onChange(newPos, Number(this.props.tileNum) + 1);
        } else {
            return false;
        }
    }
    moveTo(page_index) {
        let newPos = (page_index - 1) * 100 * -1;
        this.props.onChange(newPos, page_index);
    }
    render() {
        const { loaded } = this.state;
        if (!loaded) {
            return false;
        } else {
            return this.props.children;
        }
    }
}
