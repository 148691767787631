import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Content from '../../components/content/content';
import makepaymentInfo from './makepayment_info/makepayment_info';

class MakePayment extends Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>A L I S M A - Giveaway - April 2023</title>
                    <meta
                        name="description"
                        content="We listen because we care. We understand the importance in building a high degree of trust and excellence in the products and services we provide."
                    />
                </Helmet>
                <Content info={makepaymentInfo} className="contact" />
            </React.Fragment>
        );
    }
}

export default MakePayment;
