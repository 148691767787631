import React, { useEffect, useState } from 'react';
import toggleHeaderTheme from '../../../functions/toggle_header_theme';
// import backgroundImage from '../../../assets/images/pages/about/design-work-2021-09-02-10-58-44-utc.jpg';
import Clock from '../../../functions/clock';
// import weatherIcons from '../weather_icons/weather_icons';

const WeatherIntro = (props) => {
    const [currentWeather, setCurrentWeather] = useState([]);
    const [currentTime, setCurrentTime] = useState([]);

    const getWeather = (zip, fore) => {
        return new Promise((resolve, reject) => {
            let method = 'GET';
            let url =
                'https://api.openweathermap.org/data/2.5/' +
                fore +
                '?zip=' +
                zip +
                ',us&APPID=a653083843308721673736fc9ed43688';
            let xhr = new XMLHttpRequest();
            xhr.open(method, url);
            xhr.onload = resolve;
            xhr.onerror = reject;
            xhr.send();
        });
    };
    useEffect(() => {
        if (props.inView) {
            toggleHeaderTheme({ logo: 'light', menu: 'white' });
        }
        getWeather(55320, 'weather').then(
            (e) => {
                let response = JSON.parse(e.target.response);

                console.log(response);

                let responseTemp = JSON.parse(e.target.response).main.temp;

                let responseIcon = JSON.parse(e.target.response).weather[0]
                    .icon;

                responseTemp = 1.8 * (responseTemp - 273) + 32;

                let temp =
                    String(responseTemp).slice(
                        0,
                        String(responseTemp).indexOf('.')
                    ) + '°';

                setCurrentWeather({
                    weather: temp,
                    weatherIcon:
                        'https://openweathermap.org/img/wn/' +
                        responseIcon +
                        '@2x.png',
                });
            },
            (e) => {
                // handle errors
            }
        );
        getWeather(55320, 'forecast').then(
            (e) => {
                let response = JSON.parse(e.target.response);

                console.log(response);

                // let responseTemp = JSON.parse(e.target.response).main.temp;

                // let responseIcon = JSON.parse(e.target.response).weather[0]
                //     .icon;

                // responseTemp = 1.8 * (responseTemp - 273) + 32;

                // let temp =
                //     String(responseTemp).slice(
                //         0,
                //         String(responseTemp).indexOf('.')
                //     ) + '°';

                // setCurrentWeather({
                //     weather: temp,
                //     weatherIcon:
                //         'https://openweathermap.org/img/wn/' +
                //         responseIcon +
                //         '@2x.png',
                // });
            },
            (e) => {
                // handle errors
            }
        );
        const intervalClock = setInterval(() => {
            setCurrentTime(Clock().time);
        }, 1000);
        return () => {
            clearInterval(intervalClock);
        };
    }, []);
    return (
        <section className="tile--section weather-forcast ">
            <div className="tile--section--main--container">
                <div className="tile--section--inner--container">
                    <div className="main--info--left tile--info--dark">
                        <p>Today's Weather</p>
                    </div>
                    <div className="side--info--left tile--info--dark">
                        <p>We believe in transparency.</p>
                    </div>
                </div>
                <div className="tile--section--inner--container tile--section--media--container">
                    <div className="weather-now">
                        <div>
                            <div className="weather-now-icon">
                                <img src={currentWeather.weatherIcon} alt="" />
                            </div>
                            <div className="weather-now-info">
                                <span>
                                    Currently - {currentWeather.weather}
                                </span>
                                {currentTime}
                            </div>
                        </div>
                        {/* <div>{_strDateLong(_today())}</div> */}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WeatherIntro;
