import React from 'react';
import MakePaymentTile from '../makepayment_tiles/makepayment_tile';
import ContactTile from '../../contact/contact_tiles/contact_tile';

// Default Layout
// caption: --Main info
// text: --Alt Info
// button: --Button text
// path: --React Router Path
// type: --Tile Type
// background: --SVG Background pulled via import
// background-image : --Image address
// component: <Component />

const makepaymentInfo = [
    { type: 'component', component: <MakePaymentTile /> },
    // {
    //     type: 'component',
    //     component: <ContactTile />,
    // },
];
// const contactInfo =
//     window.innerWidth < 800
//         ? [
//               { type: 'component', component: <ContactTile formOnly={true} /> },
//               {
//                   type: 'component',
//                   component: <ContactTile infoOnly={true} />,
//               },
//           ]
//         : [{ type: 'component', component: <ContactTile /> }];

export default makepaymentInfo;
