import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Content from '../../components/content/content';
import weatherInfo from './weather_info/weather_info';

class Weather extends Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>A L I S M A - Weather Forcast</title>
                    <meta
                        name="description"
                        content="See your local weather forcast!"
                    />
                </Helmet>
                <Content info={weatherInfo} />
            </React.Fragment>
        );
    }
}

export default Weather;
