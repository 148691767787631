const Clock = () => {
    let timeRaw = new Date();

    let date = new Date().toDateString();

    let amPm = 'am';

    let greeting = 'Hello, ';

    let hours = timeRaw.getHours();

    if (hours >= 12) {
        timeRaw.setHours(hours - 12);

        amPm = 'pm';

        hours > 17
            ? (greeting = 'Good Evening, ')
            : (greeting = 'Good Afternoon, ');
    } else if (hours === 0) {
        timeRaw.setHours(12);
    } else if (hours > 2 && hours < 12) {
        hours < 6
            ? (greeting = 'You look lovely this early, ')
            : (greeting = 'Good Morning, ');
    }

    let time = timeRaw.toTimeString();

    time = time.slice(0, 5);

    Number(time[0]) === 0
        ? (time = time.slice(1) + amPm)
        : (time = time + amPm);

    let dateTime = {
        time: time,
        date: date,
        greeting: greeting,
    };

    return dateTime;
};

export default Clock;
