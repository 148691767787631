import React from 'react';
import TextAreaRaw from '../../../../components/inputs/textAreaRaw';
import TextInput from '../../../../components/inputs/textInput';

function FormContent(props) {
    return (
        <div className="form-content">
            <h1 className="form-header">Contact Us</h1>
            <div className="form-elements">
                <div className="form-element-group">
                    <TextInput
                        id="name"
                        name="name"
                        label="My name is:"
                        placeholder="Your Name"
                        value={props.formInfo.name || ''}
                        required
                        hasErrors={props.hasErrors}
                        onChange={(value) => props.onChange('name', value)}
                    />
                    <TextInput
                        id="phone"
                        name="phone"
                        label="My phone number is:"
                        placeholder="Your phone number"
                        value={props.formInfo.phone || ''}
                        hasErrors={props.hasErrors}
                        onChange={(value) => props.onChange('phone', value)}
                    />
                    <TextInput
                        id="email"
                        name="email"
                        label="My email is:"
                        placeholder="Your email address"
                        required
                        value={props.formInfo.email || ''}
                        hasErrors={props.hasErrors}
                        onChange={(value) => props.onChange('email', value)}
                    />
                    <TextInput
                        id="workAt"
                        name="workAt"
                        label="I work at:"
                        placeholder="Your business name"
                        value={props.formInfo.workAt || ''}
                        hasErrors={props.hasErrors}
                        onChange={(value) => props.onChange('workAt', value)}
                    />
                </div>
                <div className="form-element-group">
                    <TextAreaRaw
                        id="message"
                        name="message"
                        label="What I have to say is:"
                        placeholder="Type your message here..."
                        size="lg"
                        value={props.formInfo.message || ''}
                        required
                        hasErrors={props.hasErrors}
                        onChange={(value) => props.onChange('message', value)}
                    />
                    {props.children}
                </div>
            </div>
        </div>
    );
}

export default FormContent;
