import React from 'react';
import ContactTile from '../../contact/contact_tiles/contact_tile';
import WeatherIntro from '../weather_tiles/weather_intro';

// Default Layout
// caption: --Main info
// text: --Alt Info
// button: --Button text
// path: --React Router Path
// type: --Tile Type
// background: --SVG Background pulled via import
// background-image : --Image address
// component: <Component />

const weatherInfo = [
    {
        type: 'component',
        component: <WeatherIntro />,
    },

    {
        type: 'component',
        component: <ContactTile />,
    },
];

export default weatherInfo;
