import React, { useEffect, useState } from 'react';
import Payment from './form/Payment';

import { loadStripe } from '@stripe/stripe-js';

const MakePaymentTile = (props) => {
    const [stripePromise, setStripePromise] = useState(null);

    useEffect(() => {
        fetch('/config').then(async (r) => {
            const { publishableKey } = await r.json();
            setStripePromise(loadStripe(publishableKey));
        });
    }, []);

    return <Payment stripePromise={stripePromise} />;
};

export default MakePaymentTile;
